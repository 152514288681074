import React from "react";
import PortraitGrid from "../components/Organisms/PortraitGrid";
import { graphql, Link } from "gatsby";
import { JuriesTemplateQuery } from "../../graphql-schema-types";
import routeHelper, { getYearRoute } from "../routeHelper";
import { Heading1 } from "../components/Atoms/Text";
import { MainCategoryHeading } from "../components/Atoms/CategoryHeading";
import { css } from "@emotion/core";
import leftArrow from "../assets/leftArrow.svg";
import { bpMedium, bpLarge } from "../stylingConstants";
import { verticalMargin } from "../margins";

type Props = {
  data: JuriesTemplateQuery;
};

export default function Juries({ data }: Props) {
  return (
    <>
      <Heading1>Jury</Heading1>
      {data.allContentfulYear.nodes.map((item) => {
        if (!item.year || item.jury?.length === 0) {
          return null;
        }
        return (
          <>
            <Link to={getYearRoute(item.year)}>
              <MainCategoryHeading
                css={css`
                  ::after {
                    content: url(${leftArrow});
                    display: inline-block;
                    margin-left: 20px;
                    position: relative;
                    top: 0px;
                    transform: rotate(180deg);
                    margin-left: 10px;
                    @media ${bpMedium} {
                      transform: scale(1.2) rotate(180deg);
                      margin-left: 15px;
                      top: 2px;
                    }
                    @media ${bpLarge} {
                      transform: scale(1.5) rotate(180deg);
                      margin-left: 20px;
                      top: 11px;
                    }
                  }
                `}
              >
                {item.year}
              </MainCategoryHeading>
              <hr
                css={css`
                border-top: 1px solid #dedede;
                border-bottom: none;
                ${verticalMargin(25)}
                @media ${bpLarge}{
                  ${verticalMargin(35)}
                }
              `}
              />
            </Link>
            <PortraitGrid
              maxNumberOfColumns={5}
              people={item.jury!.map((juror) => ({
                name: juror?.name ?? undefined,
                image: juror?.image?.fluid!,
                href: routeHelper.getPersonInYearRoute(juror!, item.year),
              }))}
            />
          </>
        );
      })}
    </>
  );
}

export const query = graphql`
  query JuriesTemplate {
    allContentfulYear(sort: { fields: [year], order: DESC }) {
      nodes {
        jury {
          ...PersonSelection
        }
        year
      }
    }
  }
`;
